<template>
  <div>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
      <v-card-title>New Sponsor</v-card-title>
        <v-card-text>
            <template>
              <Sponsor
                @input="form.sponsorId = $event"
                :value="form['sponsorId']"
                :action="action"
              />
          </template>
           <template>
            <FormInput lable="Amount" type="number" :value="form['amount']" @input="form['amount'] = $event" />
          </template>
           <template>
            <FormInput lable="Type" type="text" :value="form['type']" @input="form['type'] = $event" />
          </template>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="$router.go(-1)"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :loading="api.isLoading"
          @click="validate"
        >
          Confirm
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-form>
    <!-- EOC -->
  </div>
</template>


<script>
import Sponsor from "@/components/Moderator/FestivalSponsor/Sponsor";
import FormInput from "@/components/Moderator/FestivalSponsor/FormInput";
export default {
  props:[
      'self',
      'role',
      'model',
      'url',
      'action',
      'callbackSuccess',
    ],
  data: () => ({
    //BOC:[api]
      api:{
        isLoading:false,
        isError:false,
        error:null,
        url:null,
      },
      //EOC
       form: {
        amount:null,
        type:null,
        sponsorId:null
       },
  }),

  components: {
    Sponsor,
    FormInput,
  },
 created() {

      //BOC:[api]
      this.api.url = this.url
      this.api.callbackReset = () => {
        this.api.isLoading = true
        this.api.isError = false
        this.api.error = null
      }
      this.api.callbackError = (e) => {
        this.api.isLoading = false
        this.api.isError = true
        this.api.error = e
      }
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false
        this.callbackSuccess(resp)
      }
      //EOC
    },
  methods: {
    selectEvent(item) {
      this.form.sponsorId = item.id
    },
    //BOC:[form]
      validate() {
        this.api.callbackReset()
        if(this.$refs.form.validate()) {
          this.submit()
        }
        else {
          this.api.callbackError('Please check the form.')
        }
      },
      submit() {
        this.api.params = this.$_.clone(this.form)
        this.$api.fetch(this.api)
      },
      //EOC
  },
};
</script>
